<template>
  <div class="person-detail page">
    <div class="person-detail__header header">
      <a @click="$router.go(-1)" class="go-back"><i class="fas fa-chevron-left"></i></a>
      <span class="person-detail__title header__title">Биография</span>
      <div class="person-detail__header-right header__right">
        <button class="btn person-detail__header-btn like-button" @click="changeWishlist">
          <i class="far fa-star" v-if="!isLike"></i>
          <i class="fa fa-star" v-else></i>
        </button>
        <button class="btn person-detail__header-btn share-button" @click="share"><i class="fas fa-share"></i></button>
      </div>
    </div>
    <div class="person-detail__image">
      <img :src="personInfo.img" alt="">
    </div>
    <div class="person-detail__player" v-if="this.personInfo.audio_files">
      <div class="container">
        <div class="player">
          <button @click="justplay" id="play" class="btn player__btn">
            <i class="fa fa-play" aria-hidden="true" v-if="!playingSong"></i>
            <i class="fa fa-pause" aria-hidden="true" v-else></i>
          </button>
          <input type="range" min="0" max="100" v-model="slider" id="duration_slider" @change="change_duration">
          <button id="mute" class="btn player__btn mute" @click="volumeOff"><i class="fas fa-volume-up" v-if="!mute"></i><i class="fas fa-volume-mute" v-else></i></button>
        </div>
        <!-- <audio id="audio-player" src="http://person.lezgikim.ru/wp-content/uploads/2019/06/Шейх-Мухаммад-аль-Яраги-l-Наставник-имамов.-_imanislamsunna.ru_.mp3" type="audio/mp3" controls></audio> -->
      </div>
    </div>
    <div class="person-detail__info">
      <div class="container">
        <strong class="person-detail__info--name">{{personInfo.title}}</strong>
        <div class="person-detail__info--text">
          {{personInfo.content}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Person',
  props: ['personId'],
  data() {
    return {
      persons: null,
      favoritePersons: [],
      personInfo: null, 
      track: null,
      play: null,
      playingSong: false,
      playButton: true,
      slider: 0,
      sliderPosition: 0,
      isLike: false,
      mute: false,
      timer: null,
    }
  },
  methods: {
    changeWishlist() {
      if(!this.isLike) {
        this.addToWishlist()
      } else {
        this.deleteFromWishlist()
      }
    },
    addToWishlist() {
      this.isLike = true

      if (localStorage.getItem('wishlist') === null) {
        localStorage.setItem('wishlist', JSON.stringify(this.favoritePersons));
      } else {
        this.favoritePersons = JSON.parse(localStorage.getItem('wishlist'));
        console.log('parse ' + this.favoritePersons)
      }

      var person = this.persons.find(person => person.id === this.personId);

      if(person) {
        this.favoritePersons.push(person); 
        localStorage.setItem('wishlist', JSON.stringify(this.favoritePersons))
      }
    },
    deleteFromWishlist() {
      this.isLike = false

      var wishlist = JSON.parse(localStorage.getItem('wishlist'));
  
      this.favoritePersons = wishlist.filter((person) => person.id !== this.personId);

      localStorage.setItem('wishlist', JSON.stringify(this.favoritePersons))
    },
    justplay(){
      if(this.playingSong == false){
        this.playsong()
      } else{
        this.pausesong()
      }
    },
    playsong(){
      this.track.play();
      this.playingSong = true;
      console.log('play')
    },
    pausesong(){
      this.track.pause();
      this.playingSong = false;
      console.log('pause')
    },
    volumeOff() {
      this.mute = !this.mute
      if(this.mute) {
        this.track.muted = true
      } else {
        this.track.muted = false
      } 
    },
    reset_slider(){
      this.slider.value = 0;
    },
    change_duration(){
      this.sliderPosition = this.track.duration * (this.slider / 100);
      this.track.currentTime = this.sliderPosition;
    },
    share() {
      if (navigator.share) {
        navigator.share({
          title: this.personInfo.title,
          text: this.personInfo.description,
          url: this.personInfo.url,
        })
        .then(() => console.log('Successful share'))
        .catch((error) => console.log('Error sharing', error));
      }
    }
  },
  mounted() {
    this.play = document.querySelector('#play');
    this.track = document.createElement('audio');

    if (this.personId) {
      localStorage.setItem('personId', this.personId);

      fetch('https://person.lezgikim.ru/api.php?do=getPostById&key=156dfdd335hjkodS&PostID=' + this.personId)
      .then(response => response.json())
      .then(json => {
        this.personInfo = json
        this.track.src = this.personInfo.audio_files[0].url;
      })
    } else {
      var personId = localStorage.getItem('personId')
      fetch('https://person.lezgikim.ru/api.php?do=getPostById&key=156dfdd335hjkodS&PostID=' + personId)
      .then(response => response.json())
      .then(json => {
        this.personInfo = json
        this.track.src = this.personInfo.audio_files[0].url;
      })
    }

    fetch('https://person.lezgikim.ru/api.php?do=getPostList&key=156dfdd335hjkodS')
    .then(response => response.json())
    .then(json => {
      this.persons = json
    })

    if (localStorage.getItem('wishlist')) {
      this.favoritePersons = JSON.parse(localStorage.getItem('wishlist'));
      var isFavorite = this.favoritePersons.find(person => person.id === this.personId)
      if(isFavorite) {
        this.isLike = true
      }
    }
  },
  updated() {
    this.track.ontimeupdate = (event) => {
      this.sliderPosition = this.track.currentTime * (100 / this.track.duration);
      this.slider = this.sliderPosition
    };
  },
}
</script>

<style lang="scss">
  @import "../assets/css/person.scss";
</style>
